:root {
  --bg-color: #1a1a1a;
  --sidebar-bg: #242424;
  --header-bg: #2c2c2c;
  --text-color: #e0e0e0;
  --accent-color: #61dafb;
  --border-color: #3a3a3a;
  --input-bg: #333333;
  --hover-color: #4a4a4a;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  overflow: hidden; /* Hide body scrollbar */
}

.App {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Hide App scrollbar */
}

.App-header {
  background-color: var(--header-bg);
  padding: 20px;
  color: var(--accent-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.main-content {
  display: flex;
  flex: 1;
  overflow: hidden; /* Hide main-content scrollbar */
}

.sidebar {
  width: 300px;
  padding: 20px;
  background-color: var(--sidebar-bg);
  overflow-y: auto;
  border-right: 1px solid var(--border-color);
}

main {
  flex-grow: 1;
  overflow: auto; /* Changed from 'hidden' to 'auto' */
  padding: 20px;
  display: flex; /* Add this */
  justify-content: center; /* Add this */
  align-items: center; /* Add this */
}

.file-inputs {
  margin-bottom: 20px;
}

.file-inputs > div {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: var(--accent-color);
}

input[type="file"] {
  width: 100%;
  padding: 10px;
  background-color: var(--input-bg);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  color: var(--text-color);
  cursor: pointer;
}

input[type="file"]:hover {
  background-color: var(--hover-color);
}

.error {
  color: #ff6b6b;
  margin-bottom: 10px;
  padding: 10px;
  background-color: rgba(255, 107, 107, 0.1);
  border-radius: 4px;
}

.token-type-controls {
  margin-bottom: 20px;
}

.token-type-controls h3 {
  color: var(--accent-color);
  margin-bottom: 10px;
}

.token-type-controls label {
  display: flex;
  align-items: center;
  margin: 8px 0;
  cursor: pointer;
}

.token-type-controls input[type="checkbox"] {
  margin-right: 8px;
}

.user-guide {
  text-align: left;
  background-color: var(--input-bg);
  padding: 15px;
  border-radius: 4px;
}

.user-guide h3 {
  color: var(--accent-color);
  margin-bottom: 10px;
}

.user-guide ul {
  padding-left: 20px;
  margin: 0;
}

.user-guide li {
  margin-bottom: 8px;
}

/* Remove scrollbar styles */
::-webkit-scrollbar {
  display: none;
}

/* For Firefox */
* {
  scrollbar-width: none;
}

.search-container {
  margin-bottom: 20px;
}

.search-input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  background-color: var(--input-bg);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.search-input:focus {
  outline: none;
  border-color: var(--accent-color);
}